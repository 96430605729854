<template>
  <div class="page_container">
    <!-- 静态图片 -->
    <div class="about_banner">
      <img src="../../assets/image/about/contact.jpg" alt />
    </div>
    <!-- 导航条 -->
    <div class="about_nav">
      <div class="about_nav_box">
        <ul class="about_nav_detail">
          <li>
            <router-link to="/about">公司简介</router-link>
          </li>
          <li>
            <router-link to="/prize">荣誉资质</router-link>
          </li>
          <li>
            <router-link to="/join">加入我们</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 百度地图 -->
    <div class="contact_nav_box">
      <div class="map_box" id="dituContent"></div>
      <div class="contact_content clearfix">
        <div class="contact_content_details adress_bg left">
          地址：上海市闵行区宜山路1698号兴迪商务大厦1506室
        </div>
        <div class="contact_content_details postcode_bg left">邮编：200233</div>
        <div class="contact_content_details phone_bg left">
          郭倩：136 2850 8343
        </div>
        <div class="contact_content_details phone_bg left">
          华中、北方地区 王振清：137 1841 3836
        </div>
        <div class="contact_content_details phone_bg left">
          南方地区 石朗忠 ：139 2163 6108
        </div>
        <div class="contact_content_details email_bg left">
          邮箱：hr@bjblackhole.com
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {

    window.addEventListener("scroll", this.onScroll, false);
		//创建和初始化地图函数：
		function initMap() {
			createMap(); //创建地图
			setMapEvent(); //设置地图事件
			addMapControl(); //向地图添加控件
			addMarker(); //向地图中添加marker
		}
		//创建地图函数：
		function createMap() {
			// var map = new BMap.Map("dituContent"); //在百度地图容器中创建一个地图
			// var point = new BMap.Point(121.411786, 31.177477); //定义一个中心点坐标
      // map.centerAndZoom(point, 16); //设定地图的中心点和坐标并将地图显示在地图容器中
      var map = new BMap.Map("dituContent", {enableMapClick:false}); // 在百度地图容器中创建一个地图
      var point = new BMap.Point(120.963783, 31.609941); // 定义一个中心点坐标
      map.centerAndZoom(point, 10); // 设定地图的中心点和坐标并将地图显示在地图容器中
			window.map = map; //将map变量存储在全局
		}
		//地图事件设置函数：
		function setMapEvent() {
			map.enableDragging(); //启用地图拖拽事件，默认启用(可不写)
			map.enableScrollWheelZoom(); //启用地图滚轮放大缩小
			map.enableDoubleClickZoom(); //启用鼠标双击放大，默认启用(可不写)
			map.enableKeyboard(); //启用键盘上下左右键移动地图
		}
		//地图控件添加函数：
		function addMapControl() {
			//向地图中添加缩放控件
			var ctrl_nav = new BMap.NavigationControl({
				anchor: BMAP_ANCHOR_TOP_LEFT,
				type: BMAP_NAVIGATION_CONTROL_LARGE
			});
			map.addControl(ctrl_nav);
			//向地图中添加缩略图控件
			var ctrl_ove = new BMap.OverviewMapControl({
				anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
				isOpen: 1
			});
			map.addControl(ctrl_ove);
			//向地图中添加比例尺控件
			var ctrl_sca = new BMap.ScaleControl({ anchor: BMAP_ANCHOR_BOTTOM_LEFT });
			map.addControl(ctrl_sca);
		}
		//标注点数组
		var markerArr = [
      {
				title: "南通秉匠信息科技有限公司",
				content: "我的位置",
				point: "121.076922|32.034194",
				isOpen: 1,
				icon: { w: 21, h: 21, l: 0, t: 0, x: 6, lb: 5 }
      },
			{
				title: "上海秉匠信息科技有限公司",
				content: "我的位置",
				point: "121.397283|31.174592",
				isOpen: 1,
				icon: { w: 21, h: 21, l: 0, t: 0, x: 6, lb: 5 }
			}
		];
		//创建marker
		function addMarker() {
			for (var i = 0; i < markerArr.length; i++) {
				var json = markerArr[i];
				var p0 = json.point.split("|")[0];
				var p1 = json.point.split("|")[1];
				var point = new BMap.Point(p0, p1);
				var iconImg = createIcon(json.icon);
				var marker = new BMap.Marker(point, { icon: iconImg });
				var iw = createInfoWindow(i);
				var label = new BMap.Label(json.title, {
					offset: new BMap.Size(json.icon.lb - json.icon.x + 10, -20)
				});
				marker.setLabel(label);
				map.addOverlay(marker);
				label.setStyle({
					borderColor: "#808080",
					color: "#333",
					cursor: "pointer"
				});
				(function() {
					var index = i;
					var _iw = createInfoWindow(i);
					var _marker = marker;
					_marker.addEventListener("click", function() {
						this.openInfoWindow(_iw);
					});
					_iw.addEventListener("open", function() {
						_marker.getLabel().hide();
					});
					_iw.addEventListener("close", function() {
						_marker.getLabel().show();
					});
					label.addEventListener("click", function() {
						_marker.openInfoWindow(_iw);
					});
					if (!!json.isOpen) {
            label.show()
						// _marker.openInfoWindow(_iw);
					}
				})();
			}
		}
		//创建InfoWindow
		function createInfoWindow(i) {
			var json = markerArr[i];
			var iw = new BMap.InfoWindow(
				"<b class='iw_poi_title' title='" +
					json.title +
					"'>" +
					json.title +
					"</b><div class='iw_poi_content'>" +
					json.content +
					"</div>"
			);
			return iw;
		}
		//创建一个Icon
		function createIcon(json) {
			var icon = new BMap.Icon(
				require("../../assets/image/about/red_adress.png"),
				new BMap.Size(30, 50),
				// new BMap.Size(json.w, json.h),
				{
					// imageOffset: new BMap.Size(-json.l, -json.t),
					// infoWindowOffset: new BMap.Size(json.lb + 5, 1),
					// offset: new BMap.Size(json.x, json.h)
				}
			)
			return icon;
		}
		initMap(); //创建和初始化地图

  },
};
</script>

<style lang="less" scoped>
//  地图样式
/deep/ .map_box {
  .top {
    background-color: #fff !important;
    img {
      display: none;
    }
  }
  .bottom{
    background-color: #fff !important;
  }
  .center {
    background-color: #fff !important;
    height: 38px !important;
  }
}
/deep/ .iw_poi_title {
  color: #cc5522;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  padding-right: 13px;
  white-space: nowrap;
}

/deep/ .iw_poi_content {
  font: 16px arial, sans-serif;
  overflow: visible;
  padding-top: 4px;
  white-space: -moz-pre-wrap;
  word-wrap: break-word;
  color: #cc5522;
}
/deep/ .center {
  position: absolute !important;
  left: 126px !important;
  top: 44px !important;
}
/deep/ .BMapLabel {
  font-size: 18px !important;
  border-radius: 5px;
  color: #cc5522 !important;
  left: 10px !important;
  top: -50px !important;
  border: 1px solid #cc5522 !important;
  padding: 6px !important;
}
</style>
